import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
    Accueil: "/",
    "Consultation Analytique": "/fr/consultation-analytique",
    "Suivi des Événements": "/fr/suivi-des-événements"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/event-tracking"
);

  const EventTracking = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Suivi des Événements dans Google Analytics [Guide d'installation ${currentYear}]`}
          description="Guide pour configurer le suivi des événements pour Google Analytics moderne avec Google Tag Manager. Tutoriel avec captures d'écran et explications d'un expert certifié."
          lang="fr"
          canonical="/fr/suivi-des-événements"
          image="google-analytics-event-tracking-report-hero"
          alternateLangs={alternateLangs}
          datePublished="2024-06-18T04:32:43.188Z"
          dateModified="2024-06-18T06:56:07Z"
        />
        <MainContent article>
          <ImgScreenshot
            src="event-tracking/google-analytics-event-tracking-report-hero.png"
            alt="graphique de données d'événement provenant des rapports d'événements de Google Analytics"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Suivi des Événements</H>
          <p>
  La configuration du suivi des événements dans Google Analytics est essentielle pour l'analyse des données, car elle permet de suivre les interactions des utilisateurs. L'analyse des pages vues, des sessions et des visiteurs ne donne pas suffisamment de contexte sur l'engagement des visiteurs du site.
</p>{" "}
<p>
  Cela est résolu en collectant des données supplémentaires à partir d'événements personnalisés qui capturent les interactions des utilisateurs, comme les clics sur les liens, par exemple.
</p>
<p>
  Les événements représentent des interactions ou des occurrences utilisateur.
  <br />
  Généralement, les interactions les plus importantes sont liées à l'objectif du site web.
  <br /> Par conséquent, nous configurons le suivi des événements personnalisés pour les utiliser comme indicateur de performance afin de voir si nos objectifs sont atteints.
</p>
<p>
  Les événements peuvent être regroupés en <strong>conversions dures</strong> et <strong>conversions douces</strong> pour tenir compte de leur relation avec l'objectif global du site.
</p>
<p>
  Étant donné que les événements sont souvent la base pour surveiller les conversions dans un contexte de commerce électronique ou de remarketing, on parle parfois de suivi des conversions.
  <br />Je fais la distinction entre le suivi des conversions et le suivi des événements, car une conversion pourrait également être une page vue ou une <b>occurrence passive</b>, comme par exemple passer un certain temps sur une page de destination.
</p>
<p>
  Pour illustrer ce que signifie le suivi des événements, je vais vous montrer comment configurer le <strong>suivi des clics</strong> sur un bouton de soumission. Ensuite, vous devriez être en mesure de configurer vos propres événements de clics uniques sur n'importe quel élément que vous souhaitez - alors commençons!
</p>
<H as="h2">Qu'est-ce que le suivi des événements dans Google Analytics?</H>
<p>
  Un événement peut être soit une <strong>interaction utilisateur active</strong>, soit une <strong>occurrence passive</strong>. Les suivre signifie observer ou compter les occurrences et les mettre en relation avec les utilisateurs, les visites et d'autres dimensions pour une analyse ultérieure.
</p>
<p>Étant donné que vous êtes libre de configurer les événements comme vous le souhaitez, ils peuvent prendre de nombreuses formes différentes.</p>
<H as="h3">Exemples</H>
<ul>
  <li>ajouter un produit à un panier d'achat</li>
  <li>s'inscrire à une liste de diffusion</li>
  <li>faire défiler jusqu'au bas d'un article</li>
  <li>cliquer sur play dans une vidéo</li>
  <li>connexion utilisateur</li>
  <li>cliquer sur une image pour zoomer</li>
  <li>soumission d'un formulaire de contact</li>
  <li>clic sur un lien sortant</li>
  <li>imprimer un article</li>
  <li>télécharger un fichier</li>
</ul>
<p>
  Comme vous pouvez le voir, les événements sont généralement liés à quelque chose que l'utilisateur <em>fait</em>, basé sur la surveillance de...
</p>
<ul>
  <li>clics de souris</li>
  <li>mouvements de souris</li>
  <li>défilement</li>
  <li>navigation du navigateur</li>
  <li>entrées du clavier</li>
</ul>
<p>
  Les événements peuvent également être <em>passifs</em>, sans que l'utilisateur ne fasse quoi que ce soit activement.
  <br />
  Par exemple, lorsque l'utilisateur passe un certain <strong>temps sur une page</strong> ou lorsqu'un formulaire renvoie une <strong>erreur</strong>. Aussi, lorsqu'une <strong>fenêtre de chatbot</strong> s'ouvre pour parler au visiteur, il n'y a pas d'activité de la part de l'utilisateur.
</p>
<p>Bien qu'une <b>page vue</b> soit techniquement aussi une interaction, la version Universal de Google Analytics la plus couramment utilisée ne compte pas une page vue comme un événement. Cependant, le nouveau Google Analytics App + Web (actuellement en version bêta) utilise un modèle de données différent qui suit également les visites de pages en tant qu'événements.</p>
<H as="h2">Types d'événements</H>
<p>Nous pouvons distinguer deux types d'événements:</p>
<H as="h6">Événements actifs (avec interaction utilisateur)</H>
<ul>
  <li>clics de souris, suivi du défilement et mouvement de la souris</li>
  <li>toucher d'écran</li>
  <li>entrées du clavier</li>
</ul>
<H as="h6">Événements passifs</H>
<ul>
  <li>atteindre un seuil de temps prédéfini</li>
  <li>réponses du serveur</li>
  <li>validations programmatiques</li>
  <li>exécution de scripts tiers</li>
</ul>
<p>
  Notez qu'avec le suivi des événements actifs et passifs, vous pouvez reconstituer une grande partie du{" "}
  {/* <a href="/parcours-client"> */}
  parcours client
  {/* </a> */}
  . <br />
  Ces données vous permettent de tirer des conclusions sur l'expérience utilisateur du début à la fin. Si vous trouvez des défauts dans le parcours, vous pouvez maintenant agir pour réduire ces expériences négatives.
</p>
<H as="h2">Structure des événements Google Analytics</H>
<p>
  Chaque événement dans Google Analytics contient des champs d'événement, ou des informations sur l'événement, que nous pouvons utiliser pour décrire l'occurrence :
</p>
<ul>
  <li>
    <strong>catégorie d'événement</strong> - pour catégoriser les événements en groupes. Dans le contexte d'un formulaire de contact, par exemple, vous voudriez regrouper tous les événements dans une catégorie d'événement <em>formulaire de contact</em>
  </li>
  <li>
    <strong>action d'événement</strong> - pour décrire l'interaction, par exemple <em>soumission de formulaire</em>
  </li>
  <li>
    <strong>étiquette d'événement</strong> - pour ajouter des informations supplémentaires sur l'interaction. Par exemple, si l'utilisateur envoie <em>l'adresse de son site web</em> avec le formulaire, vous l'utilisez pour distinguer les formulaires reçus
  </li>
  <li>
    <strong>valeur de l'événement</strong> - (<em>optionnel</em>) pour ajouter une valeur numérique à l'information. Par exemple, si en moyenne chaque 25e contact conduit à une conversion de 100$, nous pourrions attribuer une valeur de <em>4</em>{" "}
    à chaque événement.
  </li>
  <li>
    <strong>non-interaction d'événement</strong> - pour distinguer une interaction utilisateur active ou passive.{" "}
    <br />
    Par défaut, il est réglé sur <code>false</code>, ce qui signifie que l'événement est considéré comme actif.{" "}
    <strong>Important :</strong> Les événements actifs affectent le calcul du <b> <Link to="/fr/wiki-analytics/taux-de-rebond">taux de rebond</Link></b> d'une page en le réduisant. Donc, si vous avez du mal avec un taux de rebond irréaliste, il est possible que certains événements soient considérés comme une interaction utilisateur <code>true</code> par erreur.
  </li>
</ul>
<H as="h2">Mettre en œuvre le suivi des événements Google Analytics</H>
<p>Il existe deux manières de mettre en œuvre le suivi des événements personnalisés :</p>
<ol>
  <li>
    <strong>Implémentez-le</strong> avec des extraits de code javascript sur tous les éléments HTML pertinents avec un gestionnaire d'événements onclick - <strong>Je ne recommande pas</strong> cette approche, car elle est sujette aux erreurs et non évolutive. Si vous voulez le faire de toute façon, vous pouvez trouver des exemples de code dans{" "}
    <strong>la documentation de suivi des événements de Google</strong> :
  </li>
</ol>
<ul>
  <li>
    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
      documentation des événements analytics.js (legacy)
    </a>
  </li>
  <li>
    <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
      documentation des événements gtag.js
    </a>
  </li>
</ul>
<ol start="2">
  <li>
    <strong>Configurez-le avec Google Tag Manager</strong> ou un autre <Link to="/fr/gestion-des-tags">système de gestion des balises</Link> de votre choix. <br />
    La facilité et la flexibilité lors de la mise en œuvre des changements, ainsi que l'évolutivité et la facilité d'intégration avec les services tiers en font la méthode de mise en œuvre préférée.
  </li>
</ol>
<p>
  Je vais expliquer comment suivre les événements avec Google Tag Manager, car c'est le système de gestion de balises le plus utilisé et accessible à tous.
</p>
<p>
  Si vous souhaitez en savoir plus sur GTM, comme comment le configurer et suivre des éléments tels que les liens sortants et les téléchargements, lisez mon <Link to="/fr/configuration-de-google-tag-manager">tutoriel Google Tag Manager</Link>.
</p>
<H as="h2">Guide pour suivre les événements avec Google Tag Manager</H>
<p>
  La configuration du suivi des événements personnalisés peut être décomposée en créant un <strong>déclencheur</strong> pour l'événement, ainsi qu'en créant une <strong>balise d'événement</strong>, qui remplit les propriétés de l'événement (
  <em>catégorie, action, étiquette</em>, etc.). <br />
  Les valeurs des propriétés de l'événement peuvent être attribuées de manière statique ou dynamique - cette dernière étant préférée pour étendre la configuration sur des sites plus vastes.
</p>
<p>
  À titre d'exemple, nous allons implémenter le suivi Google Analytics pour un clic de bouton.{" "}
  <strong>Le suivi des clics</strong> est la forme la plus courante de suivi du comportement des utilisateurs, donc l'exemple devrait être applicable à de nombreux autres cas d'utilisation.
</p>
<p>
  À ce stade, je suppose que vous avez déjà créé un ID de suivi et que le code de suivi a été implémenté sur l'ensemble du site lorsque vous avez <Link to="/fr/configuration-de-google-analytics">configuré Google Analytics</Link> avec GTM.
</p>

<H as="h3">Configurer le déclencheur d'événement dans Google Tag Manager</H>
<ul>
  <li>
    Tout d'abord, nous devons nous connecter à notre <a href="https://tagmanager.google.com">conteneur Google Tag Manager</a>{" "}
    et <strong>créer un nouveau déclencheur</strong>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
  alt="Configurer un nouveau déclencheur dans Google Tag Manager"
  className="article-img"
/>
<ul>
  <li>
    Donnez un <strong>nom</strong> à votre déclencheur, par exemple <strong>Clic - Bouton</strong>, et cliquez sur le{" "}
    <strong>champ de configuration du déclencheur</strong> pour sélectionner un type de déclencheur. Une liste de types de déclencheurs apparaîtra depuis la droite.
  </li>
  <li>
    Choisissez <strong>Tous les éléments</strong> comme type et sélectionnez que le{" "}
    <strong>déclencheur ne se déclenche que sur certains clics</strong>.
  </li>
  <li>
    Ensuite, dans le premier menu déroulant, choisissez <strong>Élément de clic</strong>. Si ce n'est pas listé, cliquez simplement sur{" "}
    <strong>Choisir une variable intégrée…</strong> et activez-la.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-variable.png"
  alt="Variable de déclencheur dans Google Tag Manager capture d'écran"
  className="article-img"
/>
<ul>
  <li>
    Dans le deuxième menu déroulant, choisissez <strong>correspond au sélecteur CSS</strong>.<br />
    Maintenant, laissez-moi expliquer ce que nous faisons :
    <br />
    Nous essayons de générer un déclencheur de clic pour un élément HTML spécifique sur votre site - celui sur lequel vous souhaitez suivre les clics. Dans mon exemple, c'est un <strong>bouton</strong>, mais cela pourrait aussi être une{" "}
    <strong>image</strong> ou une balise <code>&lt;div&gt;</code>, ou n'importe quelle autre balise HTML - alors la question est : Comment indiquer à Google Tag Manager (GTM) quel élément observer ?
    <br />
    La réponse est : <strong>sélecteurs CSS</strong> - Vous ne savez pas ce que c'est ? C'est juste une manière programmatique de spécifier des éléments HTML sur la page et je vais vous montrer une manière d'obtenir le sélecteur CSS pour n'importe quel élément avec quelques clics de souris.
  </li>
  <li>
    Dans le navigateur Google Chrome, <strong>cliquez droit</strong> sur n'importe quel élément sur lequel vous souhaitez suivre les clics et
    sélectionnez <strong>inspecter</strong>. Les outils de développement de Chrome s'ouvriront et par défaut, l'élément HTML sera présélectionné.
  </li>
  <li>
    Ensuite, il vous suffit de <strong>cliquer droit</strong> sur l'élément et de choisir <strong>Copier</strong> &gt;{" "}
    <strong>Copier le chemin JS</strong> (évitez <em>copier le sélecteur</em> - il retourne parfois des sélecteurs erronés).
  </li>
</ul>
<ImgContainerFixed width="529px">
  <ImgScreenshot
    src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
    alt="Copier le sélecteur CSS pour le suivi des clics sur bouton"
    className="article-img"
  />
</ImgContainerFixed>
<ul>
  <li>
    Si vous collez le contenu de votre presse-papiers dans un éditeur de texte, vous verrez que vous avez copié quelque chose comme :
    <br />
    <br />
    <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
    <br />
    <br />
    Nous voulons prendre la partie qui est à l'intérieur des crochets, sans les guillemets. Donc, cette partie :
    <br />
    <br />
    <code>body &gt; ul &gt; button</code>
  </li>
  <li>
    <strong>C'est le sélecteur CSS</strong> que nous devons coller dans le dernier champ de notre configuration de déclencheur dans GTM (<em>voir ci-dessous</em>).
    <br />
    <strong>Copiez</strong> cette valeur et collez-la. Cliquez sur <strong>enregistrer</strong>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-configuration.png"
  alt="Configuration du déclencheur d'événement dans Google Tag Manager"
  className="article-img"
/>
<p>Remarquez que vous pourriez faire cela avec n'importe quel élément et configurer n'importe quel déclencheur d'événement dans GTM comme vous le souhaitez.</p>
<p>
  Nous n'avons pas encore terminé. Après avoir configuré les conditions de déclenchement pour notre événement, nous devons configurer la balise d'événement Google Analytics pour nous assurer que les bonnes données sont envoyées à la propriété Google Analytics.
</p>
<H as="h3">Créer une balise d'événement personnalisée</H>
<p>
  Maintenant que nous avons un déclencheur, nous devons le combiner avec une véritable balise d'événement. Vous pouvez considérer les déclencheurs et les balises comme le <em>quand</em> et le <em>quoi</em> dans notre exemple : <br /> Le déclencheur définit <em>quand</em> nous voulons suivre un événement (<em>quand notre bouton est cliqué</em>), et maintenant nous allons définir un événement GA, qui décrit{" "}
  <em>quoi</em> nous faisons : <em>Envoyer un événement à GA incluant toutes les bonnes valeurs pour les propriétés de l'événement.</em>
</p>
<p>Commençons.</p>
<ul>
  <li>
    Dans Google Tag Manager, allez dans le menu des balises et <em>créez une nouvelle balise</em>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
  alt="Créer une balise d'événement personnalisée dans Google Tag Manager"
  className="article-img"
/>
<ul>
  <li>
    Donnez un <strong>nom</strong> à la balise comme <em>Événement Bouton</em> et cliquez sur{" "}
    <strong>configuration de la balise</strong>.
  </li>
  <li>
    Un panneau va glisser depuis le côté droit avec une liste de types de balises possibles à choisir. Sélectionnez{" "}
    <strong>Google Analytics - Universal Analytics</strong>.
  </li>
  <li>
    De retour dans la configuration de la balise, réglez le <strong>type de suivi</strong> sur <strong>événement</strong>. Vous verrez maintenant plus de champs apparaître pour les données de l'événement.
  </li>
  <li>
    Attribuons des valeurs statiques pour <strong>catégorie d'événement</strong> et <strong>action</strong> et rendons l'<strong>étiquette d'événement</strong> dynamique.
    <br />
    Appelez la <strong>catégorie</strong> <em>événements de clic</em>, par exemple, l'<strong>action</strong> est simplement{" "}
    <em>clic</em> et pour l'<strong>étiquette</strong>, cliquez sur le symbole de la brique Lego à côté.
  </li>
  <li>
    Nous voulons que l'étiquette contienne toujours le texte écrit sur l'élément HTML cliqué, donc si nous suivons plusieurs boutons, nous pourrions les distinguer.
    <br />
    Après avoir cliqué sur la brique Lego, un panneau va glisser pour <strong>choisir une variable</strong>.
    Cliquez sur <strong>variables intégrées</strong> dans le coin supérieur droit, sauf si vous voyez déjà{" "}
    <strong>texte du clic</strong> dans la liste des variables (alors sélectionnez-le simplement et sautez l'étape suivante).
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
  alt="variable intégrée pour l'étiquette d'événement dans Google Tag Manager"
  className="article-img"
/>
<ul>
  <li>
    Dans la liste des variables intégrées, activez <strong>texte du clic</strong> et GTM ajoutera cette variable dans le champ de l'étiquette entre accolades, comme ceci : <code>{"{{Click Text}}"}</code> - Les doubles accolades sont la manière interne dans GTM de référencer les variables.
  </li>
  <li>
    Le champ <strong>valeur</strong> dans la configuration de la balise peut être rempli avec une valeur que le clic de l'événement peut représenter. <br />
    Dans mon exemple, je laisse le champ <strong>vide</strong> car je n'ai aucune valeur monétaire significative pour le clic du bouton.
  </li>
  <li>
    Si l'événement est <strong>interactif</strong>, sélectionnez <strong>false</strong> pour le coup non-interactif.
    Si c'est un <strong>événement passif</strong>, sélectionnez <strong>true</strong>.
  </li>
  <li>
    Dans la variable <strong>paramètres Google Analytics</strong>, sélectionnez la variable que vous avez configurée lorsque vous avez <a href="/fr/configuration-de-google-analytics">configuré Google Analytics</a>.
  </li>
  <li>
    Si vous n'avez pas configuré de <strong>variable de paramètres</strong>, cochez simplement la case pour{" "}
    <em>Activer le remplacement des paramètres dans cette balise</em> et ajoutez l'ID de suivi (par exemple <em>UA-12345678-91</em>) de votre{" "}
    <Link to="/fr/configuration-de-google-analytics">
      code de suivi Google Analytics
    </Link>
    .
  </li>
  <li>
    Enfin, faites défiler vers le bas jusqu'à la configuration du <strong>déclenchement</strong>, <strong>cliquez dessus</strong> et
    sélectionnez le déclencheur que nous avons configuré peu avant : <em>Clic - Bouton</em>
  </li>
</ul>
<p>La configuration complète de la balise d'événement en une seule fois :</p>
<ImgContainerFixed width="371px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
    alt="Configuration de la balise d'événement dans GTM"
    className="article-img"
  />
</ImgContainerFixed>
<ul>
  <li>
    Maintenant, cliquez sur <strong>enregistrer</strong> dans le coin supérieur droit et vous avez terminé la configuration de l'événement GA.
  </li>
</ul>
<p>Super ! L'étape suivante consiste à vérifier si notre configuration de suivi des clics fonctionne.</p>
<H as="h2">Débogage des événements</H>
<p>
  Pour déboguer la configuration, entrez en <strong>mode aperçu</strong> depuis l'aperçu de Google Tag Manager :
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-preview.png"
  alt="Mode aperçu dans Google Tag Manager"
  className="article-img"
/>
<br />
<p>
  Ensuite, nous visitons la page qui contient le bouton de soumission. Grâce au mode aperçu de Google Tag Manager, certains <Link to="/fr/wiki-analytics/les-cookies">cookies</Link> sont définis dans notre navigateur, ce qui ouvrira une fenêtre de débogage en bas de la page :
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-debugging-mode.png"
  alt="débogueur Google Tag Manager pour les événements"
  className="article-img"
/>
<br />
<p>
  Si vous <strong>cliquez maintenant sur le bouton</strong> pour tester le suivi des événements, vous devriez voir un{" "}
  <strong>clic</strong> apparaître dans le panneau récapitulatif de gauche, et la fenêtre principale de débogage devrait montrer que notre événement s'est déclenché :
</p>
<ImgContainerFixed width="555px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-debugger-button-click.png"
    alt="débogueur Google Tag Manager suivi des clics"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  Si vous cliquez sur l'<strong>événement du bouton</strong>, vous pouvez vérifier les champs de l'événement qui ont été envoyés avec l'événement :
</p>
<ImgContainerFixed width="496px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
    alt="champs de l'événement dans le débogueur Google Tag Manager"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  Vous voyez comment l'<strong>étiquette d'événement</strong> a été automatiquement remplie avec “OK” ? C'est parce que nous avons utilisé la variable intégrée <strong>texte du clic</strong> dans Google Tag Manager, qui contient toujours le texte de l'élément cliqué.
</p>
<p>Plus important encore, si vous faites défiler vers le bas, vous pouvez vérifier les règles du déclencheur d'événement :</p>
<ImgContainerFixed width="413px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-event-trigger-rules.png"
    alt="règles du déclencheur d'événement dans Google Tag Manager"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  <strong>ASTUCE</strong> : Pour vérifier que votre compte Google Analytics a bien reçu toutes les données, nous pouvons visiter le <strong>rapport en temps réel pour les événements</strong> dans Google Analytics. Si quelques minutes se sont écoulées depuis le dernier clic sur le bouton, la <strong>vue des 30 dernières minutes</strong> devrait aider.
</p>
<ImgScreenshot
  src="event-tracking/google-analytics-event-tracking-realtime-report.png"
  alt="rapport en temps réel des événements dans Google Analytics"
  className="article-img"
/>
<H as="h3">Validation du sélecteur CSS</H>
<p>
  Au cas où le débogueur n'aurait pas détecté vos clics de bouton et n'aurait montré aucun événement de clic, il y a probablement un problème avec le <strong>sélecteur CSS</strong>.<br />
  <strong>ASTUCE :</strong> vous pouvez ouvrir les <strong>outils de développement Chrome</strong> sur la page avec l'élément que vous souhaitez suivre en appuyant sur <strong>F12</strong> ou <strong>clic droit</strong> &gt;{" "}
  <strong>inspecter</strong> et visiter l'onglet <strong>éléments</strong>. Si vous appuyez maintenant sur{" "}
  <strong>CTRL+F</strong> ou <strong>CMD+F</strong> sur Mac pour rechercher un élément, vous pouvez simplement{" "}
  <strong>coller votre sélecteur CSS dans le champ de recherche</strong> et les outils de développement sauteront automatiquement à l'élément :
</p>
<ImgContainerFixed width="578px">
  <ImgScreenshot
    src="event-tracking/google-developer-tools-find-by-css-selector.png"
    alt="outils de développement Google trouver l'élément avec le sélecteur CSS"
    className="article-img"
/>
</ImgContainerFixed>
<p>
  De cette façon, vous pouvez vérifier si votre sélecteur CSS fonctionne. Si les outils de développement ne sélectionnent aucun élément dans le code, il y a un problème avec le sélecteur. Il existe également des <strong>extensions de navigateur</strong> qui peuvent vous aider à obtenir le bon sélecteur.
</p>
<p>
  Après avoir débogué et corrigé les sélecteurs, nous devrions enfin avoir le suivi des clics en cours d'exécution et pouvons retourner à Google Tag Manager pour <strong>quitter le mode aperçu</strong> et <strong>soumettre les modifications</strong>.
</p>
<p>
  Félicitations jusqu'ici ! Vous avez configuré la surveillance des événements pour cliquer sur un bouton basé sur un sélecteur CSS !
</p>
<p>
  Avec la configuration maintenant opérationnelle, vous pouvez{" "}
  <Link to="/fr/configuration-de-google-analytics">configurer des objectifs d'événements</Link> dans GA ! Les objectifs de Google Analytics sont une fonctionnalité utile, car vous pouvez décomposer les rapports et voir combien de conversions ont eu lieu pour un sous-segment donné.
</p>
<p>
  Pour le configurer, vous devez simplement définir un objectif personnalisé de Google Analytics avec les champs catégorie, action et étiquette correspondant à votre événement, et il sera compté comme un objectif d'événement.
</p>
<H as="h2">Avantages et inconvénients du suivi avec des sélecteurs CSS</H>
<p>
  Il est important de mentionner que la configuration du suivi des événements basée sur les sélecteurs CSS n'est pas la méthode la plus robuste.
</p>
<p>
  Toute la configuration de l'événement sera rompue si le sélecteur CSS ne fonctionne pas. C'est une <em>dépendance critique</em>, ce qui représente une faiblesse dans cette approche de configuration.
</p>
<p>Mais pourquoi le sélecteur cesserait-il de fonctionner ?</p>
<p>
  Parce que plus le site Web est grand, plus il y a de changements sur un site, et plus il y a de personnes impliquées - peut-être simultanément dans différentes équipes.
  <br /> Dès qu'un changement affecte la structure de notre sélecteur, il cessera de fonctionner.
</p>
<p>
  Un développeur frontend peut bien ajouter des modifications à la page un jour dans le futur, ce qui pourrait faire en sorte que le sélecteur se casse. Par conséquent, il serait plus robuste d'ajouter un <strong>gestionnaire d'événements onclick</strong> à l'élément, qui pousse toutes les propriétés de l'événement vers une{" "}
  <strong>
    <Link to="/fr/couche-de-données">couche de données</Link>
  </strong>
  . C'est l'approche recommandée pour les indicateurs clés de performance (KPI) importants et pour les grandes organisations, où les changements ne sont pas surveillés.
</p>
<p>Les implémentations avancées d'analyses Web qui intègrent des bases de données et des sources de données tierces utilisent une <Link to="/fr/couche-de-données">couche de données pour suivre les événements</Link>. Veuillez lire cet article pour le code de suivi des événements requis.</p>
<p>Utiliser une couche de données est plus sécurisé et fiable, mais nécessite une implémentation approfondie.</p>
<p>Au lieu de se fier aux sélecteurs CSS, vous utilisez des écouteurs d'événements pour <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">les événements du navigateur</a> comme déclencheur. GTM utilise également des écouteurs d'événements de clic en arrière-plan lorsque vous configurez un déclencheur de clic, mais en basant le suivi sur une couche de données, vous pouvez choisir plus librement quels événements du navigateur doivent être suivis.</p>
<p>En particulier pour une application à page unique (SPA) sans chargements de page entre la navigation, une couche de données peut être bien utilisée pour suivre les <b>pages vues virtuelles</b> comme des événements et les faire compter comme des pages vues régulières dans Google Analytics.</p>
<p>
  Pour les petits sites Web, l'effort d'implémentation d'une couche de données n'est pas toujours justifiable. Dans de tels cas, le suivi des événements basé sur les sélecteurs CSS est une approche valable.
</p>
<p>
  L'<strong>avantage</strong> de l'utilisation des sélecteurs CSS est clairement la <strong>facilité</strong> d'implémentation et la rapidité avec laquelle vous pouvez commencer à collecter des données. Cela peut également être utile si une implémentation de couche de données peut prendre du temps, en raison d'un manque de ressources, car vous pouvez déjà collecter certaines des données uniquement avec des sélecteurs CSS.
</p>
<p>
  L'<strong>inconvénient</strong> est que cette méthode est presque <strong>sujette à des pannes</strong> un jour, en fonction du nombre de modifications mises en œuvre et du nombre d'équipes travaillant sur le site Web.
</p>
<p>
  Mais si vous configurez le suivi Web pour votre propre site et que vous contrôlez les
  modifications, n'hésitez pas à suivre cette méthode. Soyez simplement conscient que les modifications de la structure HTML de la page peuvent casser le sélecteur CSS.{" "}
</p>
<p>
  Cependant, les entreprises disposant d'un budget et ayant des exigences élevées en matière de qualité des données devraient choisir une couche de données comme fondement de leur configuration de suivi des événements.
</p>
<H as="h2">Rapports d'événements dans Google Analytics</H>
<p>
  Maintenant que nous avons configuré le suivi des interactions utilisateur, nous devons nous assurer de suivre les quantités d'événements envoyés au compte Google Analytics.{" "}
</p>
<p>
  La meilleure façon de le faire est soit avec le <strong>rapport d'événements</strong> intégré, soit avec un <strong>rapport personnalisé</strong> ou un <strong>tableau de bord</strong> Google Analytics.
</p>
<p>
  Dans <strong>Google Analytics</strong>, vous pouvez aller dans <strong>Comportement</strong> &gt; <strong>Événements</strong>{" "}
  &gt; <strong>Événements principaux</strong> pour trouver les données d'événements que vous avez suivies :
</p>
<ImgScreenshot
  src="event-tracking/google-analytics-event-tracking-report.png"
  alt="Aperçu du rapport de suivi des événements dans Google Analytics"
  className="article-img"
/>
<br />
<p>
  Étant donné qu'il n'y a pas de <b>nom d'événement</b> par défaut disponible dans GA, vous verrez d'abord vos catégories d'événements. Vous pouvez également choisir la dimension principale pour être <strong>action d'événement</strong> ou{" "}
  <strong>étiquette d'événement</strong> pour approfondir le rapport.
</p>
<p>
  Notez que le rapport d'événements nous donne une colonne avec <strong>Valeur moyenne</strong>, ce qui est pratique si nous avons besoin de la valeur moyenne de nos événements de clics. Cela peut être pertinent pour une application Web qui calcule les prix, où le résultat final est suivi par un événement. De cette façon, vous pourriez facilement trouver la valeur moyenne des calculs des utilisateurs.{" "}
</p>
<p>
  D'autres exemples courants de valeurs moyennes utiles sont, par exemple, les paramètres de filtre pour les prix ou les tailles dans une boutique de commerce électronique.{" "}
</p>
<p>
  Gardez donc à l'esprit qu'une <strong>valeur d'événement</strong> peut sembler non liée au début, mais il peut bien y avoir une valeur liée à attribuer à l'événement de toute façon, car il serait utile de vérifier les valeurs moyennes dans l'ensemble.
</p>
</MainContent>
</React.Fragment>

</Layout>
);

export default EventTracking;


  